<template>
    <div class="first-squad">
        <div class="players-pos porteros">
            <div class="pl-header">
                PORTEROS
                <img src="../assets/dots.png" alt="" class="dots-ti">
            </div>
            <div class="cont-players">
                <div class="squad-player" v-for="pla in porteros" :key="pla.id">
                    <a :href="'/jugadores/nombre/'+replaceAll(pla.name)" :class="{'ignore' : listaMostrar.includes(pla.num)}" >
                    <div class="data-player">
                        <div class="player-number">
                            {{pla.num}}
                        </div>
                        <div class="name-country-cont">
                            <div class="name-cont">
                                  {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.nationality}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img">
                    </a>
                </div>
            </div>
        </div>
        <div class="players-pos defensas">
            <div class="pl-header">
                DEFENSAS
                <img src="../assets/dots.png" alt="" class="dots-ti">
            </div>
            <div class="cont-players">
                <div class="squad-player" v-for="pla in defensas" :key="pla.id">
                    <a :href="'/jugadores/nombre/'+replaceAll(pla.name)">
                    <div class="data-player">
                        <div class="player-number">
                            {{pla.num}}
                        </div>
                        <div class="name-country-cont">
                            <div class="name-cont">
                                  {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.nationality}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img"></a>
                </div>
            </div>
        </div>
        <div class="players-pos medios">
            <div class="pl-header">
                MEDIOS
                <img src="../assets/dots.png" alt="" class="dots-ti">
            </div>
            <div class="cont-players">
                <div class="squad-player" v-for="pla in medios" :key="pla.id">
                    <a :href="'/jugadores/nombre/'+replaceAll(pla.name)" :class="{'ignore' : listaMostrar.includes(pla.num)}" >
                    <div class="data-player">
                        <div class="player-number">
                            {{pla.num}}
                        </div>
                        <div class="name-country-cont">
                            <div class="name-cont">
                                  {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.nationality}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img"></a>
                </div>
            </div>
        </div>
        <div class="players-pos delanteros">
            <div class="pl-header">
                DELANTEROS
                <img src="../assets/dots.png" alt="" class="dots-ti">
            </div>
            <div class="cont-players">
                <div class="squad-player" v-for="pla in delanteros" :key="pla.id">
                    <a :href="'/jugadores/nombre/'+replaceAll(pla.name)" :class="{'ignore' : listaMostrar.includes(pla.num)}" >
                    <div class="data-player">
                        <div class="player-number">
                            {{pla.num}}
                        </div>
                        <div class="name-country-cont">
                            <div class="name-cont">
                                  {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.nationality}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img"></a>
                </div>
            </div>
        </div>
        <div class="players-pos cuerpo-tecnico">
            <div class="pl-header">
                CUERPO TÉCNICO
            </div>
            <div class="cont-players">
                <div class="squad-player" v-for="pla in dt" :key="pla.id">
                    <div class="data-player">
                        <div class="name-country-cont ct">
                            <div class="name-cont">
                                {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.type_name}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img">
                </div>
                <div class="squad-player" v-for="pla in aux" :key="pla.id">
                    <div class="data-player">
                        <div class="name-country-cont ct">
                            <div class="name-cont">
                                {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.type_name}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img">
                </div>
                <div class="squad-player" v-for="pla in cuerpotecnico" :key="pla.id">
                    <div class="data-player">
                        <div class="name-country-cont ct">
                            <div class="name-cont">
                               {{splitName(pla.name)}}
                            </div>
                            <div class="country-cont">
                                {{pla.type_name}}
                            </div>
                        </div>
                    </div>
                    <img :src="url+'/get-player-image/'+pla.image" alt="player" class="player-img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            idPlayer: 1,
            listaMostrar:[171]
        };
    },
    created() {
        this.findByTeam3();
    },
    computed: {
        url() {
        return this.$store.getters["main/baseURL"];
        },
        porteros() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type == 2) {
                    playah.push(match[i]);
                }
            }return playah;
        },
        defensas() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type == 1) {
                    playah.push(match[i]);
                }
            }return playah;
        },
        medios() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type == 3) {
                    playah.push(match[i]);
                }
            }return playah;
        },
        delanteros() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type == 13) {
                    playah.push(match[i]);
                }
            }return playah;
        },
        dt() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type == 4) {
                    playah.push(match[i]);
                }
            }return playah;
        },
        aux() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type == 5) {
                    playah.push(match[i]);
                }
            }return playah;
        },
        cuerpotecnico() {
            let match = this.$store.getters["players/data3"];
            let playah = [];
            for (let i = 0; i < match.length; i++) {
                if (match[i].id_type !== 1 && match[i].id_type !== 2 && match[i].id_type !== 3 && 
                match[i].id_type !== 4 && match[i].id_type !== 5 && match[i].id_type !== 13) {
                    playah.push(match[i]);
                }
            }return playah;
        },
    },
    methods: {
    ...mapActions("players", ["findByTeam3"]),

        findByTeam3: async function () {
        let result = await this.$store.dispatch("players/findByTeam3", {
            option: "players/team", id: 4
        });
        //console.log(result)
       this.info = result;
        return result;
        },
        replaceAll(name){
            let var1 = name.replaceAll(' ','-')
            return var1.toLowerCase();
        },
        splitName(name){
            let splitname = name.split(' ');
            if(splitname.length == 3){
                return splitname[0] +' '+ splitname[1]
            }else if(splitname.length == 4){
                return splitname[0] +' '+ splitname[2]
            }else if(splitname.length == 5){
                return splitname[0] +' '+ splitname[3]
            }else{
                return splitname[0] +' '+ splitname[1]
            }
        }
    },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Plantillas - Cimarrones de Sonora';
            }
        },
    }
}
</script>

<style>
    
</style>